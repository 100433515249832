import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import { usePartyStore } from "../../store/localPartyStore.js";
import AddressList from "./AddressList";

const AddPartyForm = ({ onClose }) => {
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const [organizer, setOrganizer] = useState("");
  const [type, setType] = useState([]);
  const [address, setAddress] = useState("");
  const [settingLocation, setSettingLocation] = useState(false);
  const [modelIsOpen, setModelIsOpen] = useState(true);
  const setMarkerPosition = usePartyStore((state) => state.setMarkerPosition);
  const latitude = usePartyStore((state) => state.latitude);
  const setLatitude = usePartyStore((state) => state.setLatitude);
  const longitude = usePartyStore((state) => state.longitude);
  const setLongitude = usePartyStore((state) => state.setLongitude);
  const setSnackbarMessage = usePartyStore((state) => state.setSnackbarMessage);
  const [partyLink, setPartyLink] = useState("");
  const [linkError, setLinkError] = useState(false);

  const setSnackbarSeverity = usePartyStore(
    (state) => state.setSnackbarSeverity
  );
  const setSnackbarOpen = usePartyStore((state) => state.setSnackbarOpen);
  const setPartySearchTerm = usePartyStore((state) => state.setPartySearchTerm);
  const setAddressLatLong = (address) => {
    setLatitude(address.latitude);
    setLongitude(address.longitude);
    setAddress(address.address);
  };

  const partyTypeOptions = [
    "Bachata",
    "Kizomba",
    "Salsa",
    "West Coast Swing",
    "Zouk",
  ];

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setType(value);
  };

  const map = useMap();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      partyLink &&
      !partyLink.startsWith("https://fb.me/e/") &&
      !partyLink.startsWith("https://www.instagram.com/p/")
    ) {
      setLinkError(true);
      return;
    } else {
      setLinkError(false);
    }

    const newParty = {
      name,
      type: type.join(", "),
      date,
      latitude,
      longitude,
      address: address.address ? address.address : "",
      email,
      organizer,
      link: partyLink,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/party-request/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newParty),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setSnackbarMessage(data.message);
      setSnackbarSeverity("info");
      setSnackbarOpen(true);
      setMarkerPosition(null);
      onClose();
    } catch (error) {
      setSnackbarMessage("Error creating party. Please try again later.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleMapClick = (e) => {
    if (settingLocation) {
      setLatitude(e.latlng.lat);
      setLongitude(e.latlng.lng);
      setPartySearchTerm(
        `${e.latlng.lat.toFixed(2)}, ${e.latlng.lng.toFixed(2)}`
      );
      setSettingLocation(false);
      setModelIsOpen(true);
      setMarkerPosition(e.latlng);
    }
  };

  useEffect(() => {
    if (map && settingLocation) {
      map.on("click", handleMapClick);
    }
    return () => {
      if (map) {
        map.off("click", handleMapClick);
      }
    };
  }, [map, settingLocation]);

  const handleSetLocation = () => {
    setSettingLocation(true);
    setModelIsOpen(false);
  };

  return (
    <Modal open={modelIsOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Add Your Party
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Party Name"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="party-type-label">Party Types</InputLabel>
            <Select
              labelId="party-type-label"
              id="party-type"
              multiple
              value={type}
              onChange={handleChange}
              input={<OutlinedInput label="Party Types" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {partyTypeOptions.map((typeOption) => (
                <MenuItem key={typeOption} value={typeOption}>
                  <Checkbox checked={type.indexOf(typeOption) > -1} />
                  <ListItemText primary={typeOption} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Date"
            type="date"
            fullWidth
            margin="normal"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={10}>
              <AddressList setAddressLatLong={setAddressLatLong} />
            </Grid>
            <Grid item xs={2}>
              <Button variant="outlined" onClick={handleSetLocation}>
                Set
              </Button>
            </Grid>
          </Grid>
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            label="Organizer"
            fullWidth
            margin="normal"
            value={organizer}
            onChange={(e) => setOrganizer(e.target.value)}
          />
          <TextField
            label="Party Link"
            fullWidth
            margin="normal"
            value={partyLink}
            onChange={(e) => setPartyLink(e.target.value)}
            placeholder="Facebook event or Instagram post"
            error={linkError}
            helperText={linkError && "Invalid party link"}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AddPartyForm;
