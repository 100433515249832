import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useFavoritesStore = create(
  persist(
    (set) => ({
      favorites: {
        festivals: [],
        localParties: [],
      },
      toggleFavorite: (type, itemId) =>
        set((state) => ({
          favorites: {
            ...state.favorites,
            [type]: state.favorites[type].includes(itemId)
              ? state.favorites[type].filter((id) => id !== itemId)
              : [...state.favorites[type], itemId],
          },
        })),
    }),
    {
      name: "favorites-store",
      getStorage: () => localStorage,
    }
  )
);