import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useStore } from "../../store/store.js";
import "./Dictionary.css";
import DictionarySearchBar from "./DictionarySearchBar.js";
import DictionarySmallHorSwiper from "./DictionarySmallHorSwiper.js";

// Import Swiper styles
import "swiper/css";
import "swiper/css/manipulation";
import "swiper/css/pagination";

function DictionarySmall() {
  const danceStore = useStore();

  const [verticalSwiperRef, setVerticalSwiperRef] = useState(null);
  const [shouldUpdateFiguresList, setShouldUpdateFiguresList] = useState(true);
  const [figuresList, setFiguresList] = useState([]);
  const [showTutorial, setShowTutorial] = useState(true); // Initially show tutorial

  const handleSwiperInit = (swiper) => {
    // Hide the tutorial after a few seconds or when the user interacts with the Swiper
    setTimeout(() => {
      setShowTutorial(false);
    }, 5000); // Hide after 5 seconds

    swiper.on("slideChange", () => {
      setShowTutorial(false);
    });
  };

  function getActiveFigureName() {
    const activeIndex = verticalSwiperRef?.activeIndex;
    const figure_name = figuresList[activeIndex]?.figures[0].name;
    return figure_name;
  }

  useEffect(() => {
    if (danceStore.currentFigure.length > 0 && shouldUpdateFiguresList) {
      setFiguresList([
        { id: 0, figures: danceStore.currentFigure },
        { id: 1, figures: danceStore.nextFigures },
      ]);
      setShouldUpdateFiguresList(false);
    }
  }, [danceStore.currentFigure]);

  useEffect(() => {
    if (verticalSwiperRef && !verticalSwiperRef.destroyed) {
      const activeIndex = verticalSwiperRef?.activeIndex;
      setFiguresList((oldNextFiguresList) => {
        const newId = activeIndex + 1;
        const existingIndex = oldNextFiguresList.findIndex(
          (item) => item.id === newId
        );

        if (existingIndex !== -1) {
          // If an object with the same ID exists, replace it
          const updatedList = [...oldNextFiguresList];
          updatedList[existingIndex] = {
            id: newId,
            figures: danceStore.nextFigures,
          };
          return updatedList.slice(0, existingIndex + 1);
        } else {
          // If no object with the same ID exists, append a new one
          return [
            ...oldNextFiguresList,
            { id: newId, figures: danceStore.nextFigures },
          ];
        }
      });
    }
  }, [danceStore.nextFigures]);

  async function addSlide() {
    const figure_name = getActiveFigureName();
    danceStore.fetchNextFigure(figure_name);
  }

  const swiper = useRef();

  function handleStepChange(e) {
    // run if the last slide is active
    if (e.realIndex === figuresList.length - 1) {
      addSlide();
    }
  }
  const verticalSettings = {
    onInit: handleSwiperInit,
    onSwiper: setVerticalSwiperRef,
    slidesPerView: 1.2,
    ref: swiper,
    direction: "vertical",
    pagination: {
      clickable: true,
      dynamicBullets: true,
    },
    modules: [Pagination, Navigation],
    onSlideChange: handleStepChange,
    className: "vertical-swiper",
  };

  if (danceStore.currentFigure.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <DictionarySearchBar setFiguresList={setFiguresList} />
      {/* Instructions for vertical and horizontal swipes */}
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        gutterBottom
      >
        Swipe <b>vertically</b> to next figure
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        gutterBottom
      >
        Swipe <b>horizontally</b> to alternative next figures
      </Typography>

      {showTutorial && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 10, // Ensure it's on top
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
            color: "white",
            padding: 2,
            borderRadius: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pointerEvents: "none", // Allow clicks to pass through
          }}
        >
          <ArrowDownwardIcon sx={{ fontSize: 40, mb: 1 }} />{" "}
          {/* Downward arrow icon */}
          <Typography variant="body1">Swipe down to see next figure</Typography>
        </Box>
      )}
      <Swiper {...verticalSettings} style={{ height: "80vh" }}>
        {figuresList.map((item, index) => {
          return (
            <SwiperSlide key={`${item.id}-${index}`} className="swiperSlide">
              <DictionarySmallHorSwiper
                figuresItem={item.figures}
                figuresList={figuresList}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}

export default DictionarySmall;
