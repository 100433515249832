import { Container, Divider, Grid, Typography } from "@mui/material";
import * as React from "react";
import { useStore } from "../../store/store.js";
import FigureBox from "../figureBox/FigureBox.js";
import DictionarySearchBar from "./DictionarySearchBar.js";

function Dictionary() {
  const danceStore = useStore();

  const content =
    danceStore.currentFigure.length > 0 ? (
      <>
        <Container
          maxWidth="lg"
          sx={{ backgroundColor: "#faf8ff", pt: "24px" }}
        >
          <DictionarySearchBar />
          <Typography
            variant="body1"
            sx={{
              color: "rgba(0, 0, 0, 0.87)",
              textAlign: "center",
              fontWeight: 500,
            }}
          >
            Left column shows the <b>current figure</b>, right column shows the{" "}
            <b>next possible figures</b>. <br /> Click on a next figure to make
            it a <b>current figure</b> and call its corresponding{" "}
            <b>next figures</b>.
          </Typography>
        </Container>
        <Container
          maxWidth="lg"
          sx={{ backgroundColor: "#faf8ff", pt: "24px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h5" sx={{ color: "black" }}>
                Current figure
              </Typography>
              <Divider orientation="vertical" flexItem sx={{ mb: 2 }} />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h5"
                className="home-page-title"
                sx={{
                  color: "black",
                }}
              >
                Next possible figures
              </Typography>
              <Divider orientation="vertical" flexItem sx={{ mb: 2 }} />
            </Grid>
          </Grid>
          <Grid container>
            {/* first Grid item */}
            <Grid
              item
              xs={6}
              container
              justifyContent="center"
              alignItems="flex-start"
            >
              {danceStore.currentFigure.map((figure) => (
                <FigureBox
                  key={figure.id}
                  figureData={figure}
                  isCurrentFigure={true}
                />
              ))}
            </Grid>
            {/* second Grid item */}
            <Grid item xs={6}>
              <Grid
                container
                direction="row" // Arrange items vertically
                spacing={2}
                sx={{ height: "70vh", overflowY: "auto" }}
              >
                {danceStore.nextFigures.map((figure) => (
                  <Grid
                    item
                    xs={12}
                    key={figure.id}
                    container
                    justifyContent="center"
                    alignItems="flex-start"
                  >
                    <FigureBox figureData={figure} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </>
    ) : (
      <Typography
        variant="h5"
        sx={{ color: "black", backgroundColor: "white", my: 2 }}
      >
        Choose a dance style
      </Typography>
    );

  return (
    <Container maxWidth="lg" sx={{ height: "70vh" }}>
      {content}
    </Container>
  );
}

export default Dictionary;
