import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import React from "react";
import { useFavoritesStore } from "../../store/favoritesStore";

function PartyFavoriteButton({ party }) {
  const toggleFavorite = useFavoritesStore((state) => state.toggleFavorite);

  const isFavorite = useFavoritesStore((state) =>
    state.favorites["localParties"].includes(party.id)
  );

  const handleToggleFavorite = () => {
    toggleFavorite("localParties", party.id); // Pass the festival ID to the store
  };

  return (
    <>
      {isFavorite ? (
        <FavoriteIcon
          onClick={handleToggleFavorite}
          style={{ cursor: "pointer" }}
          sx={{ color: "red" }}
        />
      ) : (
        <FavoriteBorderIcon
          onClick={handleToggleFavorite}
          style={{ cursor: "pointer" }}
          sx={{
            color: "black",
          }}
        />
      )}
    </>
  );
}

export default PartyFavoriteButton;
