import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import Impressum_photo from "./impressum_photo.jpeg";

function Impressum() {
  return (
    <Container maxWidth="md" sx={{ padding: "2rem" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4" gutterBottom>
            Impressum
          </Typography>
          <Typography variant="h6" paragraph>
            Angaben gemäß § 5 TMG
          </Typography>
          <Typography variant="body1" paragraph>
            Momen Mawad & Leonie Lötsch <br />
            Pyrmonter Str. 4 <br />
            51065 Cologne <br />
            Germany <br />
          </Typography>
          <Typography variant="body1" paragraph>
            E-Mail: info@dancemaps.net <br />
          </Typography>
          <Typography variant="body1" paragraph>
            DanceMaps provides information about dance figures and events for
            informational purposes only. We do not guarantee the accuracy,
            completeness, or reliability of any information on the website. We
            are not responsible for any errors or omissions, or for the results
            obtained from the use of this information.
          </Typography>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <img
            src={Impressum_photo}
            alt="Impressum"
            style={{ width: "70%", height: "auto" }}
          />
        </Grid> */}

        {/* <Grid item xs={12} sm={6}>
          <Typography variant="body1" paragraph>
            Leonie Lötsch <br />
            Wormser Str. 13C <br />
            50677 Cologne <br />
            Germany <br />
          </Typography>
        </Grid> */}
      </Grid>

      {/* Disclaimer (Optional) */}
    </Container>
  );
}

export default Impressum;
