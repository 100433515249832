import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useStore } from "../../store/store.js";

function DictionarySearchBar({ setFiguresList }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const adjustName = (name) => {
    let adjustedName = name
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
    return adjustedName;
  };

  const danceStore = useStore();
  const style = danceStore.style;

  const handleSearchChange = (event) => {
    setSearchTerm(adjustName(event.target.value));
    setShowSuggestions(true);
  };

  useEffect(() => {
    const debounceTimer = setTimeout(async () => {
      if (searchTerm && showSuggestions) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/figures/?selected_style=${style}&search=${searchTerm}`
          );
          if (response.ok) {
            const data = await response.json();
            const names = data.map((figure) => figure.name).flat();
            setSuggestions(names);
          } else {
            console.error("Error fetching suggestions:", response.statusText);
          }
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        }
      } else {
        setSuggestions([]);
      }
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchTerm]);

  const handleSearchSubmit = async (suggestion) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/figures/?selected_style=${style}&search=${suggestion}`
      );

      if (response.ok) {
        const data = await response.json();

        if (isSmallScreen) {
          setFiguresList([
            { id: 0, figures: data },
            { id: 1, figures: data[0].next_figure },
          ]);
        }

        danceStore.setCurrentFigure(style, data[0].name);
        danceStore.fetchNextFigure(data[0].name);
      } else {
        console.error("Error searching for figures:", response.statusText);
      }
    } catch (error) {
      console.error("Error searching for figures:", error);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion);
    handleSearchSubmit(suggestion);
    setSuggestions([]);
    setShowSuggestions(false);
    setShowSearch(false);
  };

  return (
    <Box sx={{ position: "absolute", top: "10%", zIndex: 11 }}>
      <IconButton
        onClick={() => setShowSearch(!showSearch)}
        sx={{
          "& svg": {
            color: "primary.main",
          },
        }}
      >
        <SearchIcon fontSize={isSmallScreen ? "medium" : "large"} />
      </IconButton>

      {showSearch && (
        <Box
          sx={{
            position: "relative",
            zIndex: 12,
            backgroundColor: "white",
          }}
        >
          <TextField
            label="Search Figures"
            placeholder={`Search in ${adjustName(style)}`}
            value={searchTerm}
            onChange={handleSearchChange}
            fullWidth
            autoFocus
          />
          {suggestions.length > 0 && (
            <List>
              {suggestions.map((suggestion, index) => (
                <ListItem
                  key={index}
                  onClick={() => handleSuggestionClick(adjustName(suggestion))}
                >
                  <ListItemText primary={adjustName(suggestion)} />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      )}
    </Box>
  );
}

export default DictionarySearchBar;
