import {
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import Link from "@mui/material/Link";
import React from "react";

function PrivacyPolicy() {
  return (
    <Container maxWidth="md" sx={{ padding: "2rem" }}>
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>

      <Typography variant="body1" paragraph>
        At DanceMaps, we respect your privacy and are committed to protecting
        your personal information. This Privacy Policy explains how we collect,
        use, and safeguard your information when you use our website and
        services.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Information We Collect:
      </Typography>

      <List>
        <ListItem>
          <ListItemText
            primary="Log Data:"
            secondary="When you visit our website, our servers automatically record information that your browser sends. This may include your IP address, browser type, the pages you visit, and the time spent on each page."
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary="Contact Form Data:"
            secondary="If you contact us through our contact form, we collect your name, email address, and the message you submit."
          />
        </ListItem>
      </List>

      <Typography variant="h5" gutterBottom>
        How We Use Your Information:
      </Typography>

      <List>
        <ListItem>
          <ListItemText
            primary="To Improve Our Website:"
            secondary="We may use the information we collect to analyze trends, administer the site, track user movement, and gather demographic information to improve our website and services."
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary="To Respond to Your Inquiries:"
            secondary="We may use your email address to respond to your inquiries, questions, and/or other requests."
          />
        </ListItem>
      </List>

      {/* Add sections for Data Sharing, Cookies, Your Rights, Security, etc. based on your specific practices */}

      <Typography variant="h5" gutterBottom>
        Contact Us:
      </Typography>

      <Typography variant="body1">
        If you have any questions or concerns about our Privacy Policy, please
        contact us <Link href="/contact">here</Link>.
      </Typography>
    </Container>
  );
}

export default PrivacyPolicy;
