import { create } from "zustand";

export const usePartyStore = create((set) => ({
  parties: [],
  markerPosition: null,
  address: "",
  latitude: 50.93736,
  longitude: 6.95879,
  partySearchTerm: "",
  snackbarMessage: "",
  snackbarSeverity: "success",
  snackbarOpen: false,

  setParties: (parties) => set({ parties }),
  setMarkerPosition: (markerPosition) => set({ markerPosition }),
  setAddress: (address) => set({ address }),
  setLatitude: (latitude) => set({ latitude }),
  setLongitude: (longitude) => set({ longitude }),
  setPartySearchTerm: (partySearchTerm) => set({ partySearchTerm }),
  setSnackbarMessage: (snackbarMessage) => set({ snackbarMessage }),
  setSnackbarSeverity: (snackbarSeverity) => set({ snackbarSeverity }),
  setSnackbarOpen: (snackbarOpen) => set({ snackbarOpen }),
}));
