import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/de";
import L from "leaflet";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "react-leaflet-markercluster/dist/styles.min.css";
import { usePartyStore } from "../../store/localPartyStore.js";
import AddPartyForm from "./AddPartyForm";
import markerIconPng from "./marker-icon.png";
import PartyFavoriteButton from "./PartyFavoriteButton";

const RecenterMap = () => {
  const map = useMap();
  const latitude = usePartyStore((state) => state.latitude);
  const longitude = usePartyStore((state) => state.longitude);

  useEffect(() => {
    if (latitude && longitude) {
      map.panTo([latitude, longitude]);
    }
  }, [latitude, longitude, map]);

  return null;
};

const LocalParty = () => {
  const [parties, setParties] = useState([]);
  const [filteredParties, setFilteredParties] = useState([]);
  const [partyTypeFilter, setPartyTypeFilter] = useState([]);
  const [dateFilter, setDateFilter] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [disabledDates, setDisabledDates] = useState([]);
  const markerPosition = usePartyStore((state) => state.markerPosition);
  const latitude = usePartyStore((state) => state.latitude);
  const longitude = usePartyStore((state) => state.longitude);
  const setLatitude = usePartyStore((state) => state.setLatitude);
  const setLongitude = usePartyStore((state) => state.setLongitude);
  const snackbarMessage = usePartyStore((state) => state.snackbarMessage);
  const snackbarSeverity = usePartyStore((state) => state.snackbarSeverity);
  const snackbarOpen = usePartyStore((state) => state.snackbarOpen);
  const setSnackbarOpen = usePartyStore((state) => state.setSnackbarOpen);

  const customIcon = new L.Icon({
    iconUrl: markerIconPng,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
  });

  const partyTypeOptions = [
    "Bachata",
    "Kizomba",
    "Salsa",
    "West Coast Swing",
    "Zouk",
  ];

  useEffect(() => {
    const fetchParties = async () => {
      await fetch(`${process.env.REACT_APP_API_URL}/parties/`)
        .then((response) => response.json())
        .then((data) => {
          setParties(data);
          setFilteredParties(data);
        });

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          (error) => {
            console.error("Error getting location:", error);
          }
        );
      } else {
        console.warn("Geolocation is not supported by this browser.");
      }
    };

    fetchParties();
  }, []);

  useEffect(() => {
    const filtered = parties.filter((party) => {
      if (partyTypeFilter.length === 0 && !dateFilter) {
        return true;
      }

      const typeMatch =
        partyTypeFilter.length === 0 ||
        partyTypeFilter.some((filterType) => party.type.includes(filterType));
      const dateMatch = !dateFilter || party.date === dateFilter; // Add date filter logic
      return typeMatch && dateMatch; // Combine type and date filters
    });
    setFilteredParties(filtered);
  }, [parties, partyTypeFilter, dateFilter]);

  const handlePartyTypeFilterChange = (event) => {
    const {
      target: { value },
    } = event;
    setPartyTypeFilter(typeof value === "string" ? value.split(",") : value);
  };

  const handleDateFilterChange = (newValue) => {
    if (newValue && newValue.isValid()) {
      // Check if newValue is valid
      setDateFilter(newValue.format("YYYY-MM-DD")); // Only update if valid
    } else {
      setDateFilter(""); // Clear the date if invalid or null
    }
  };

  useEffect(() => {
    const fetchPartyDates = async () => {
      await fetch(`${process.env.REACT_APP_API_URL}/parties/party_dates/`)
        .then((response) => response.json())
        .then((data) => {
          setDisabledDates(data);
        });
    };

    fetchPartyDates();
  }, []);

  return (
    <div>
      <MapContainer
        center={[latitude, longitude]}
        zoom={12}
        style={{ height: "84vh" }}
      >
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        />
        <MarkerClusterGroup
          key={filteredParties.map((party) => party.id).join(",")}
        >
          {filteredParties.map((party) => (
            <Marker
              key={party.id}
              position={[party.latitude, party.longitude]}
              icon={customIcon}
            >
              <Popup closeOnClick={true} autoClose={false}>
                <Card sx={{ maxWidth: 200, position: "relative" }}>
                  <Box
                    sx={{ position: "relative", top: 8, right: -5, zIndex: 1 }}
                  >
                    <PartyFavoriteButton party={party} />
                  </Box>
                  <CardContent sx={{ padding: 1 }}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{
                        fontSize: 16,
                        marginBottom: 1,
                        maxWidth: 200,
                      }}
                    >
                      {party.name}
                    </Typography>
                    {party.organizer && (
                      <Typography
                        variant="h3"
                        color="text.secondary"
                        sx={{ fontSize: 12, marginBottom: 0.25 }}
                      >
                        Organizer: {party.organizer}
                      </Typography>
                    )}
                    <Typography
                      variant="h3"
                      color="text.secondary"
                      sx={{ fontSize: 12, marginBottom: 0.25 }}
                    >
                      {party.type}
                    </Typography>
                    <Typography
                      variant="h3"
                      color="text.secondary"
                      sx={{ fontSize: 12 }}
                    >
                      {party.date}
                    </Typography>
                  </CardContent>
                  {party.link && (
                    <CardActions sx={{ padding: 1 }}>
                      <Button
                        size="small"
                        href={party.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ fontSize: 12 }}
                      >
                        Go to Party
                      </Button>
                    </CardActions>
                  )}
                </Card>
              </Popup>
            </Marker>
          ))}
        </MarkerClusterGroup>
        ;
        <div
          style={{
            position: "absolute",
            top: 10,
            left: 10,
            zIndex: 1000,
            backgroundColor: "white",
            padding: "1rem 1rem 0.5rem 1rem",
            borderRadius: "4px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="party-type-filter-label">Filter</InputLabel>
              <Select
                labelId="party-type-filter-label"
                id="party-type-filter"
                multiple // Enable multiple selections
                value={partyTypeFilter}
                input={<OutlinedInput label="Party Type" />}
                renderValue={(selected) =>
                  selected.length > 0 ? selected.join(", ") : "All"
                }
                onChange={handlePartyTypeFilterChange}
              >
                {partyTypeOptions.map((typeOption) => (
                  <MenuItem key={typeOption} value={typeOption}>
                    <Checkbox
                      checked={partyTypeFilter.indexOf(typeOption) > -1}
                    />
                    <ListItemText primary={typeOption} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
              <DatePicker
                label="Date Filter"
                value={dateFilter ? dayjs(dateFilter) : null}
                onChange={(newValue) => {
                  if (newValue === null) {
                    setDateFilter(""); // Clear dateFilter if newValue is null
                  } else {
                    handleDateFilterChange(newValue);
                  }
                }}
                shouldDisableDate={(date) => {
                  return (
                    date < dayjs().subtract(1, "day") ||
                    !disabledDates.includes(date.format("YYYY-MM-DD"))
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                slotProps={{ field: { clearable: true } }}
              />
            </LocalizationProvider>
          </Box>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 10,
            left: 10,
            zIndex: 1000,
            padding: "1rem",
          }}
        >
          <Button variant="contained" onClick={() => setShowForm(true)}>
            Add Your Party
          </Button>
        </div>
        {showForm && <AddPartyForm onClose={() => setShowForm(false)} />}
        {markerPosition && (
          <Marker
            position={markerPosition}
            icon={
              new L.icon({
                iconUrl: markerIconPng,
                iconSize: ["auto", 40],
                iconAnchor: [12, 40],
              })
            }
          >
            {/* You can add a popup here if needed */}
            <Popup>Party Location</Popup>
          </Marker>
        )}
        <RecenterMap />
      </MapContainer>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default LocalParty;
