import { Box, List, ListItem, ListItemText, TextField } from "@mui/material";
import { debounce } from "lodash";
import React, { useState } from "react";
import { usePartyStore } from "../../store/localPartyStore.js";

const AddressList = ({ setAddressLatLong }) => {
  const [suggestions, setSuggestions] = useState([]);
  const setLatitude = usePartyStore((state) => state.setLatitude);
  const setLongitude = usePartyStore((state) => state.setLongitude);
  const partySearchTerm = usePartyStore((state) => state.partySearchTerm);
  const setPartySearchTerm = usePartyStore((state) => state.setPartySearchTerm);
  const setMarkerPosition = usePartyStore((state) => state.setMarkerPosition);

  const handleSearchChange = debounce((event) => {
    const newAddress = event.target.value;
    setPartySearchTerm(newAddress);
    setSuggestions([]);

    fetch(`${process.env.REACT_APP_API_URL}/address_search/?q=${newAddress}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // console log all the addresses found
        setSuggestions(data);
      })
      .catch((error) => {
        console.error("Error fetching addresses:", error);
        // TODO: Handle the error, e.g., show an error message to the user
      });
  }, 2000);

  const handleSuggestionClick = (suggestion) => {
    setAddressLatLong(suggestion);
    setPartySearchTerm(suggestion.address);
    setLatitude(suggestion.latitude);
    setLongitude(suggestion.longitude);
    setMarkerPosition([suggestion.latitude, suggestion.longitude]);
    setSuggestions([]);
  };

  return (
    <Box
      sx={{
        position: "relative",
        zIndex: 12,
        backgroundColor: "white",
      }}
    >
      <TextField
        label="Search Address"
        placeholder="Enter address or set location"
        onChange={(event) => {
          setPartySearchTerm(event.target.value);
          handleSearchChange(event);
        }}
        value={partySearchTerm}
        fullWidth
        autoFocus
      />
      <Box
        sx={{
          position: "absolute",
          top: "100%",
          left: 0,
          width: "100%",
          zIndex: 13,
        }}
      >
        {suggestions.length > 0 && (
          <List dense>
            {suggestions.map((suggestion, index) => (
              <ListItem
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                sx={{
                  cursor: "pointer",
                  "&:hover": { backgroundColor: "primary.light" },
                  backgroundColor: "white",
                }}
              >
                <ListItemText primary={suggestion.address} />
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    </Box>
  );
};

export default AddressList;
