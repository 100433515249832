// create a horizontal swiper carousel in react
import { Box } from "@mui/material";
import React, { useState } from "react";
import "swiper/css";
import "swiper/css/manipulation";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useStore } from "../../store/store.js";
import "./Dictionary.css";

import FigureBox from "../figureBox/FigureBox.js";

function DictionarySmallHorSwiper({ figuresItem }) {
  const danceStore = useStore();

  const [horizontalSwiperRef, setHorizontalSwiperRef] = useState(null);

  function getActiveFigureName() {
    const activeIndex = horizontalSwiperRef?.activeIndex;
    const currentSlide = horizontalSwiperRef?.slides[activeIndex];
    const figure_name = currentSlide.querySelector(".figure-box").id;
    return figure_name;
  }

  const handleHorizontalChange = () => {
    const figure_name = getActiveFigureName();
    danceStore.fetchNextFigure(figure_name);
  };

  var horizontalSettings = {
    onSwiper: setHorizontalSwiperRef,
    slidesPerView: 1,
    centeredSlides: true,
    pagination: {
      clickable: true,
      dynamicBullets: true,
    },
    modules: [Pagination],
    onSlideChange: () => handleHorizontalChange(),
  };

  return (
    <Swiper {...horizontalSettings} style={{ height: "60vh" }}>
      {figuresItem?.map((figure) => (
        <SwiperSlide key={figure.id}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <FigureBox figureData={figure} />
          </Box>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default DictionarySmallHorSwiper;
