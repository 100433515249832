import { Container, Divider, Typography } from "@mui/material";
import React from "react";

function Disclaimer() {
  return (
    <Container maxWidth="md" sx={{ padding: "2rem" }}>
      <Typography variant="h4" gutterBottom align="center">
        Disclaimer
      </Typography>
      <Typography variant="body1" paragraph>
        **DanceMaps is a supplementary resource designed to help you remember
        and refine your dance moves.** It is not intended to replace the
        invaluable guidance and personalized instruction you receive from dance
        teachers and schools. We encourage all users to seek formal dance
        education to truly master the intricacies and nuances of each figure.
      </Typography>
      <Divider sx={{ my: 2 }} /> {/* Add a divider for visual separation */}
      <Typography variant="body1" paragraph>
        The information presented on DanceMaps, including dance figures,
        tutorials, and event details, is provided for general informational
        purposes only. While we make every effort to ensure the accuracy and
        timeliness of this information, we cannot guarantee its completeness or
        reliability.
      </Typography>
      <Typography variant="body1" paragraph>
        Any reliance you place on the information found on DanceMaps is strictly
        at your own risk. We are not liable for any errors, omissions, or any
        loss or damage that may arise from the use of this information. We
        strongly recommend verifying any information before acting upon it.
      </Typography>
      <Typography variant="body1" paragraph>
        Under no circumstances shall DanceMaps be held responsible for any
        direct, indirect, incidental, special, or consequential damages arising
        out of the use of this website.
      </Typography>
    </Container>
  );
}

export default Disclaimer;
